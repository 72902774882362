 /* * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background-color: #e9e9e9;
    color: #000000;
    padding: 10px;
    overflow: auto;
  }
  
  .container {
    padding: 10px 150px;
  }
  
  p {
    font-size: 14px;
    text-align: justify;
  }
  
  .caroussel-content {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  [class*="col-"] {
    padding: 10px;
  }
  
  .card {
    width: 100%;
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0/20%), 0 2px 2px 0 rgb(0 0 0/14%),
      0 1px 5px 0 rgb(0 0 0/12%);
  }
  
  .mt-5 {
    margin-top: 50px;
  }
  .mb-10 {
    margin-bottom: 100px;
  }
  
  @media screen and (max-width: 800px) {
    .container {
      padding: 10px;
    }
  }
   

   */
   /* Global styles */
* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    background-color: #e9e9e9;
    color: #000000;
    padding: 10px;
    overflow: auto;
  }
  
  /* Container styles */
  .container {
    padding: 10px 20px; /* Adjust the container padding for better responsiveness */
    max-width: 1200px; /* Add a max-width to prevent content from becoming too wide */
    margin: 0 auto; /* Center the container horizontally */
    
  }
  
  /* Paragraph styles */
  p {
    font-size: 16px; /* Increase font size for better readability */
    text-align: justify;
  }
  
  /* Caroussel content styles */
  /* .caroussel-content {
    margin-left: -10px;
    margin-right: -10px;
  } */
  
  /* Column styles */
  [class*="col-"] {
    padding: 10px;
  }
  
  /* Card styles */
  .card {
    width: 100%;
    background-color: #fff;
    padding: 15px;
    margin: 50px;
    border-radius: 40px;
    margin:"20px 50px 100px 0px" ;
    text-align: center;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .card-image{
    width: 85%;
  }
  
  /* Margin classes for spacing */
  .mt-5 {
    margin-top: 20px; /* Reduce the margin for smaller screens */
  }
  
  .mb-10 {
    margin-bottom: 40px; /* Reduce the margin for smaller screens */
  }
  
  /* Responsive media query for screens up to 800px */
  @media screen and (max-width: 800px) {
    .container {
      padding: 10px; /* Adjust container padding for smaller screens */
    }
    
    p {
      font-size: 14px; /* Reduce font size for smaller screens */
    }
    
    .mt-5 {
      margin-top: 10px; /* Reduce the margin for smaller screens */
    }
    
    .mb-10 {
      margin-bottom: 20px; /* Reduce the margin for smaller screens */
    }
  }
  